@keyframes starfall-animation {
  0% {
    transform: translateY(-20px) rotate(0deg);
    opacity: 0;
  }
  5% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: translateY(50vh) rotate(360deg);
    opacity: 0;
  }
}

@keyframes breathe {
  0%,
  100% {
    opacity: 0.4;
    transform: scale(0.9);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
}
.animate-breathe {
  animation: breathe 3s ease-in-out infinite;
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
    background-color: white;
    border-color: #e2e8f0;
  }
  10%,
  90% {
    transform: translateX(-6px);
    background-color: rgb(254, 226, 226);
    border-color: rgb(239, 68, 68);
  }
  20%,
  80% {
    transform: translateX(6px);
    background-color: rgb(254, 202, 202);
    border-color: rgb(239, 68, 68);
  }
  30%,
  50%,
  70% {
    transform: translateX(-8px);
    background-color: rgb(254, 226, 226);
    border-color: rgb(239, 68, 68);
  }
  40%,
  60% {
    transform: translateX(8px);
    background-color: rgb(254, 202, 202);
    border-color: rgb(239, 68, 68);
  }
}
.animate-shake {
  animation: shake 0.5s ease-in-out forwards;
}

.slide-left {
  transform: translateX(-100%);
}

.slide-right {
  transform: translateX(100%);
}

.slide-center {
  transform: translateX(0);
}

.transition-transform {
  transition: transform 500ms ease-in-out;
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.shimmer {
  background: linear-gradient(90deg, #f6f7f8 0%, #edeef1 50%, #f6f7f8 100%);
  background-size: 200% 100%;
  animation: shimmer 10s infinite linear;
}
